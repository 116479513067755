.categories-page {
    max-width: 100%;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .category-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    @media (max-width: 800px) {
      flex-direction: column;
  }
  }
  .coming-soon{
    width: 100%;
    height: 80vh;
  }
  .mobile-view{
    display: none;
    @media (max-width:800px) {
      display: block;
    }
  }
  .desktop-view{
    display: block;
    @media (max-width:800px) {
      display: none;
    }
  }
  .product-heading-plp{
    text-align: center;
    font-size: 30px;
    margin-bottom:50px;
  }
  .product-description-plp{
    font-size: 17.5px;
    margin-bottom:50px;
  }
  .product-coming-soon{
    width: 100%;
    height: 100%;
  }
  .category-item {
    background-color: #333;
    padding: 20px;
    flex: 1;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    color: white;
    text-align: center;
  }
  
  .view-products-btn {
    background-color: white;
    color: #333;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .view-products-btn:hover {
    background-color: whitesmoke;
  }
  