/* src/components/Recipes.css */

.recipes-container {
    padding: 20px;
    max-width: 100vw;
    margin: auto;
    font-family: 'Arial', sans-serif;
}

.recipes-title {
    text-align: center;
    margin-bottom: 20px;
}

.recipes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.recipe-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    transition: transform 0.2s;
    background-color: #333;
    color: white !important;
    padding:20px;
}
.recipe-card  a{
    color: white;
}

.recipe-card:hover {
    transform: scale(1.05);
}

.recipe-image {
    width: 100%;
    height: 200px; /* Fixed height for consistency */
    object-fit: cover;
    margin-bottom:20px;
}
