.footer {
  background-color: #C0A56F;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo img {
  width: 200px;
  margin-bottom: 10px;
}

.footer-social-media {
  margin: 10px 0;
}

.footer-social-media a {
  margin: 0 10px;
  display: inline-block;
}

.footer-contact p {
  margin: 5px 0;
}
.footer-contact a{
  color: brown;
}

.footer-copyright {
  margin-top: 10px;
  font-size: 0.9em;
}
