.contact-page {
    font-family: Arial, sans-serif;
    max-width: 100vw;
    margin: 0 auto;
  }
  
  .contact-page h1 {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .contact-page p {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-container {
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 20px;
    background: url('./../Products/combineProduct.png') no-repeat center center/cover;
    @media (max-width: 800px) {
      flex-direction: column;
      padding: 20px;
  }
  }
  
  .contact-form, .partner-form {
    background: black;
    opacity: 0.9;
    padding: 20px;
    width: 35%;
    height: 90%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    @media (max-width:800px) {
      width: auto;
    }
  }
  
  .contact-form h2, .partner-form h2 {
    margin-bottom: 15px;
    font-size: 1.5em;
    text-align: center;
    color: #ffffff;
  }
  
  .contact-form input, .partner-form input,
  .contact-form select, .partner-form select,
  .contact-form textarea, .partner-form textarea {
    width: -webkit-fill-available;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 0px;
  }

  .contact-form select, .partner-form select{
    width: 100% !important;
  }
    /* Hero Section */
    .hero-section-contact {
      background: url('./Contact.webp') no-repeat center center/cover;
      height: 80vh;
      display: flex;
      align-items: center;
      justify-content: end;
      color: white;
      position: relative;
      text-align: center;
    }
    
    .hero-content-contact {
      background: rgba(0, 0, 0, 0.6);
      width: 60%;
      padding: 30px;
      border-radius: 10px;
      margin: 0px auto;
      @media (max-width: 800px) {
        padding: 20px;
        width: 80%;
    }
    }
    
    .hero-title-contact {
      font-size: 2rem;
      margin-bottom: 15px;
      text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
      @media (max-width: 800px) {
       font-size: 1.5rem;
    }
    }
    
    .hero-subtitle-contact {
      font-size: 1.25rem;
      margin-bottom: 30px;
      @media (max-width: 800px) {
        font-size: 1rem;
    }
    }
    .hero-buttons-contact{
      display: flex;
      justify-content: center;
      align-items: center;
      gap:10px;
      @media (max-width: 800px) {
        flex-direction: column;
    }
    }
    
    .hero-buttons-contact .btn-primary, .hero-buttons-contact .btn-secondary {
      padding: 10px 25px;
      border-radius: 5px;
      font-size: 1.2rem;
      margin: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      @media (max-width: 800px) {
        width: -webkit-fill-available;
    }
    }
    .feedback-button{
      padding: 10px 25px;
      border-radius: 5px;
      font-size: 1rem;
      margin: 10px auto;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      @media (max-width: 800px) {
        width: -webkit-fill-available;
    }
    }
    
    .btn-primary {
      background-color: #ff5733;
      color: white;
      cursor: pointer;
    }
    
    .btn-primary:hover {
      background-color: #c93d1a;
    }
    
    .btn-secondary {
      background-color: transparent;
      border: 2px solid white;
      color: white;
    }
    
    .btn-secondary:hover {
      background-color: white;
      color: #333;
    }
  